<template>
  <div id="">
    <!-- preloader section -->
    <div class="preloader">
      <div class="sk-spinner sk-spinner-rotating-plane"></div>
    </div>
    <!-- home section -->
    <div id="homewrap">
      <section id="home" class="img-responsive">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <!--<h1 class="wow bounceInDown rotate">ROMNA</h1>
              <h2 class="wow bounce">DESIGN STUDIO</h2>
              <a href="#intro" class="btn btn-default smoothScroll">GET STARTED</a>-->
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- navigation section -->
    <div class="navbar navbar-default navbar-static-top" role="navigation">
      <div class="container">
        <div class="navbar-header">
          <button class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> <span class="icon icon-bar"></span> <span class="icon icon-bar"></span> <span class="icon icon-bar"></span> </button>
          <a href="#" class="navbar-brand">
            <img alt="Brand" style="height:inherit; display:initial;" src="@/assets/images/fulhamTexas_official_logo.jpg"> &nbsp;<span style='color:black;'>Fulham Texas</span>
          </a>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li><a href="#home" class="smoothScroll">HOME</a></li>
            <li><a href="#intro" class="smoothScroll">INTRO</a></li>
            <!--<li><a href="#work" class="smoothScroll">WORK</a></li>-->
            <li><a href="#team" class="smoothScroll">EVENTS</a></li>
            <li><a href="#portfolio" class="smoothScroll">GALLERY</a></li>
            <!--<li><a href="#price" class="smoothScroll">PRICE</a></li>-->
            <li><a href="#contact" class="smoothScroll">CONTACT</a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- intro section -->
    <section id="intro">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-2 col-md-8 col-md-offset-2  col-sm-offset-1 col-sm-10 col-sm-offset-1 title">
            <h2>WELCOME</h2>
            <h5>About Fulham Texas</h5>
            <hr>
            <p style='text-align: left;'>Fulham Texas Supporters Club was founded in 2012 by Dean Kakridas (@ATX). We became an official International Supporters Club in  2013. Simply started with friends, brought together through our common love for Fulham, watching FFC games in a pub in downtown Austin. Over the years it’s evolved into an amazing community of people in Austin, Houston, Dallas, San Antonio and everywhere in between. Texas is like a country onto itself with ~30 million people, the 10th largest GDP in the world, and an exploding global footy fanbase. There’s no doubt that Fulham draws the very best fans everywhere and we want to help usher them into the best football club fanbase on the planet! </p>

<p style='text-align: left;'>There would be no FulhamTexas without amazing people like Sam Vedamanikan (@samved09), Stephen Watson (@swatsonffc), Drew Browning, Matt (@tnukeep1) and Heather (@HeatherFSmith1) Smith, Justin Miller (@jmill186), Brian Progar  (@brianprogar), Ward Hoffman (@tomahawkWard), Keith Austin (@kaustin24), to name a few. Many of us try to make a yearly trip to London to catch a game at the Cottage and connect with local friends.</p>

<p style='text-align: left;'>It’s a privilege to represent the club we love in Texas and our aim is to do that by honoring the game, the great history of Fulham Football Club, the great people of SW6 and beyond, and showing America what a proper English club and fanbase looks like. </p>

<p style='text-align: left;'>You can find us online: Twitter (@FulhamTexas) and Facebook (@FulhamTX) and in pubs all over the state. We would like nothing more than to host traveling Fulham family for some world famous BBQ (https://franklinbbq.com/), Craven Cottage beer (https://www.roadmapbrewing.com/ontap), and friendly Texas hospitality. Nuestra casa es tu casa! 
</p>
          </div>
        </div>
      </div>
    </section>
    <!-- event section -->
    <section id="team">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-3 col-md-6 col-md-offset-3  col-sm-offset-2 col-sm-8 col-sm-offset-2 title">
            <h2>Upcoming Events</h2>
            <hr>
            <p>Check here for all upcoming events. Updates will be posted here, as well as on our social media platforms.</p>
          </div>
        </div>
        <div class="row">
          <!--<div class="col-md-4 col-sm-4 col-xs-6 wow fadeIn col-centered" data-wow-delay="0.9s">
            <div class="team-des">
              <h4>Fulham Vs Everton</h4>
              <h3>Burnet Go To</h3>
              <h3>8/12/2023 9am (CST)</h3>
              <h5>6800 Burnet Rd #2, Austin, Tx 78758</h5>
            </div>
          </div><br />-->
          <div v-for="(item, i) in events" :key="i" class="col-md-4 col-sm-4 col-xs-6 wow fadeIn col-centered" data-wow-delay="1.3s">
            <div class="team-des">
              <h4>{{item.TITLE}}</h4>
              <h3>{{item.LOCATION}}</h3>
              <h3>{{item.DATE}}</h3>
              <h5><a style='color:white' :href="item.GOOGLE_ADDRESS">{{item.ADDRESS}}</a></h5>
            </div>
          </div>          
          <!--<div class="col-md-4 col-sm-4 col-xs-6 wow fadeIn" data-wow-delay="1.3s"><img src="images/team2.jpg" class="img-responsive" alt="team img">
            <div class="team-des">
              <h4>Linda</h4>
              <h3>Manager</h3>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-6 wow fadeIn" data-wow-delay="1.6s"><img src="images/team3.jpg" class="img-responsive" alt="team img">
            <div class="team-des">
              <h4>Mary</h4>
              <h3>Developer</h3>
            </div>
          </div>-->
        </div>
      </div>
    </section>
    <!-- portfolio section -->
    <div id="portfolio">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-3 col-md-6 col-md-offset-3 col-sm-offset-2 col-sm-8 col-sm-offset-2 title">
            <h2>Gallery</h2>
            <hr>
            <p>Beautiful club. Beautiful people.</p>
          </div>
          <div class="col-md-12 col-sm-12"></div>
          <div v-for="(item, i) in items" :key="i" class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s">
            <a :href="item.src" data-lightbox-gallery="portfolio-gallery"><img :src="item.thumb" :alt="item.alt"></a>
          </div>
          <!--<div ><a href="images/championship_promotion_2022.jpg" ><img src="images/championship_promotion_2022_thumb.jpg" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/cottage_visit.jpg" data-lightbox-gallery="portfolio-gallery"><img src="images/cottage_visit_thumb.jpg" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/hopsquad_watch_party3.jpg" data-lightbox-gallery="portfolio-gallery"><img src="images/hopsquad_watch_party3_thumb.jpg" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/Craven_cottage_tour_2022.jpg" data-lightbox-gallery="portfolio-gallery"><img src="images/Craven_cottage_tour_2022_thumb.jpg" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/.jpg" data-lightbox-gallery="portfolio-gallery"><img src="images/fulhamTexas_official_beer_thumb.jpg" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/hopsquad_offical_pub1.png" data-lightbox-gallery="portfolio-gallery"><img src="images/hopsquad_offical_pub1_thumb.png" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/.jpg" data-lightbox-gallery="portfolio-gallery"><img src="images/hopsquad_watch_party_thumb.jpg" alt="portfolio img"></a></div>
          <div class="col-md-3 col-sm-3 wow fadeIn" data-wow-delay="0.6s"><a href="images/hopsquad_watch_party6.jpg" data-lightbox-gallery="portfolio-gallery"><img src="images/hopsquad_watch_party6_thumb.jpg" alt="portfolio img"></a></div>-->
        </div>
      </div>
    </div>
    <!-- contact section -->
    <section id="contact">
      <div class="container">
        <div class="row">
          <div class="col-md-offset-3 col-md-6 col-md-offset-3  col-sm-offset-2 col-sm-8 col-sm-offset-2 title">
            <h2>Contact Us</h2>
            <hr>
            <p>Get in touch with the Fulham Texas crew! Stay in touch for all events, news, Craven Cottage trips and more!</p>
          </div>
          <div class="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 contact-form wow fadeInUp" data-wow-delay="0.9s">
            <div id="contactForm" name="sentMessage" novalidate="novalidate">
              <input type="text" class="form-control" placeholder="Name" v-model="contactName">
              <input type="email" class="form-control" placeholder="Email" v-model="contactEmail">
              <input type="tel" class="form-control" placeholder="Phone number (xxx-xxx-xxxx format)" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" v-model="contactphone">
              <textarea class="form-control" placeholder="Message" rows="6" v-model="contactMessage"></textarea>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" style="height:auto" v-model="contactWhatsapp"> Check this box to be included in our FulhamTexas whatsapp group chat.
                  </label>
                </div>
              <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" @click="submit">Send Message</button>
              <recaptcha ref="recaptcha" @verify="submit"></recaptcha>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- footer section -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <h2 class="wow fadeIn" data-wow-delay="0.9s">Follow Us</h2>
            <ul class="social-icon">
              <li><a href="https://www.facebook.com/FulhamTX/" class="fa fa-facebook wow bounceIn" data-wow-delay="0.3s"></a></li>
              <li><a href="https://twitter.com/fulhamtexas" class="fa fa-twitter wow bounceIn" data-wow-delay="0.6s"></a></li>
              <li><a href="https://www.instagram.com/fulhamtexas/" class="fa fa-instagram wow bounceIn" data-wow-delay="0.9s"></a></li>
            </ul>
          </div>
          <div class="col-md-12 col-sm-12 copyright">
            <p>&copy; FulhamTexas
              <span id="copyright">
                  
              </span>. All Rights Reserved | Design: templatemo | Developer: <a href="http://samvedamanikam.com" target="_blank">Sam Vedamanikam</a></p>
          </div>
        </div>
      </div>
    </footer>    
  </div> <!--container div-->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import recaptcha from './components/recapcha.vue'
import axios from "axios";
export default {
  name: 'App',
  components: {
    recaptcha
  },
  data(){
    return{
      items: [
        {
          thumb: require('@/assets/images/championship_promotion_2022_thumb.jpg'),
          src: require('@/assets/images/championship_promotion_2022.jpg'),
          alt: "Fulham Texas promotion 2022"
        },
        {
          thumb: require('@/assets/images/cottage_visit_thumb.jpg'),
          src: require('@/assets/images/cottage_visit.jpg'),
          alt: "Fulham Texas cottage visit!"
        },
        {
          thumb: require('@/assets/images/hopsquad_watch_party3_thumb.jpg'),
          src: require('@/assets/images/hopsquad_watch_party3.jpg'),
          alt: "Fulham Texas watch party"
        },
        {
          thumb: require('@/assets/images/Craven_cottage_tour_2022_thumb.jpg'),
          src: require('@/assets/images/Craven_cottage_tour_2022.jpg'),
          alt: "Cottage tour"
        },
        {
          thumb: require('@/assets/images/fulhamTexas_official_beer_thumb.jpg'),
          src: require('@/assets/images/fulhamTexas_official_beer.jpg'),
          alt: "Fulham Texas official beer"
        },
        {//
          thumb: require('@/assets/images/FulhamTexas_DC_Tour_thumb.jpg'),
          src: require('@/assets/images/FulhamTexas_DC_Tour.jpg'),
          alt: "Fulham Texas "
        },//
        {
          thumb: require('@/assets/images/hopsquad_watch_party_thumb.jpg'),
          src: require('@/assets/images/hopsquad_watch_party.jpg'),
          alt: "Fulham Texas watch party."
        },
        {
          thumb: require('@/assets/images/hopsquad_watch_party6_thumb.jpg'),
          src: require('@/assets/images/hopsquad_watch_party6.jpg'),
          alt: "Fulham Texas watch party!!!"
        },                                        
      ],
      contactWhatsapp: false,
      events:[]
    }
  },
  mounted() {
    this.getevents()
  },
  methods:{
      submit: function(response){
        var success = 0
        var name = this.contactName
        var phone = this.contactphone
        var email = this.contactEmail
        var msg = this.contactMessage
        var whatsapp = ""
        if(this.contactWhatsapp){
          whatsapp = "Y"
        }else{
          whatsapp = "N"
        }
        var errors = "";
        var valid = true;
        document.getElementById("sendMessageButton").innerHTML = 'Sending...';
        document.getElementById("sendMessageButton").setAttribute("disabled", true);
        if (email == "" || msg == "" || name =="" || phone =="" ) {
            valid = false;
            errors = "All fields are required.";
        } 
        
        this.show = !this.show
        if (valid) {
          
          axios.post("https://samvedamanikam.com/heliosengine/api/Fulham/sendEmail",{
                  email: this.contactEmail,
                  message: this.contactMessage,
                  full_name: this.contactName,
                  phone: this.contactphone,
                  whatsapp: whatsapp
          }).then(function(res) {
            console.log(res)
            success = success+1
            document.getElementById("sendMessageButton").innerHTML = 'Sent!';
          })
          .catch(function(error) {
            console.log(error)
          })
          this.executeRecaptcha()                
          // ajax to the php file to send the mail
          axios.post("https://samvedamanikam.com/heliosengine/api/recaptcha/validateFulhamTX",{
            email: this.contactEmail,
            message: this.contactMessage,
            name: this.contactName,
            phone: this.contactphone,
            whatsapp: whatsapp,
            grecaptcharesponse: response
            })
          .then(response => {
              console.log(response.data)                 
              success = success+1
            })
          .catch(function(error) {
            console.log(error)
            if(success > 0){
              this.show = !this.show
              this.contactName = "";
              this.contactEmail = "";
              this.contactMessage = "";
              this.contactphone = ""
              document.getElementById("sendMessageButton").innerHTML = 'Sent!';
            }else{
              document.getElementById("sendMessageButton").innerHTML = 'Error!';
            }
          })
          if(success > 0){
            this.show = !this.show
            this.contactName = "";
            this.contactEmail = "";
            this.contactMessage = "";
            this.contactphone = ""
            document.getElementById("sendMessageButton").innerHTML = 'Sent!';
          }else{
            document.getElementById("sendMessageButton").innerHTML = 'Error!';
          }
        } else {
            this.errorMessage = errors;
        }	
      },
      onVerify: function (response) {
        console.log(response)
        return response
      },
      onExpired: function () {
        console.log('Expired')
      },
      enter: function() {

        var that = this;

        setTimeout(function() {
          that.show = false;
        }, 6000); // hide the message after 3 seconds
      },executeRecaptcha () {
        this.$refs.recaptcha.execute()
      },
      getevents: function(){
          axios.get("https://samvedamanikam.com/heliosengine/api/Fulham/events")
          .then(response => {
              this.events = response.data
              console.log("^^^^^^^^^^^^^")
              console.log(this.events)
            })
          .catch(function(error) {
            console.log(error)
          })
      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.col-centered{
    float:none;
    margin: 0 auto;
}

@import './assets/css/bootstrap.min.css';
@import './assets/css/animate.min.css';
@import './assets/css/font-awesome.min.css';
@import './assets/css/nivo-lightbox.css';
@import './assets/css/nivo_themes/default/default.css';
@import './assets/css/templatemo-style.css';
</style>
